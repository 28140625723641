import auth from './modules/auth'
import block from './modules/block'
import options from './modules/options'
import Forms from './modules/CRUDS/forms'
import Vuex from 'vuex'
import Vue from 'vue'
import snackbar from "./modules/snackbar";
import Regions from "./modules/CRUDS/Regions";
import Pages from "./modules/CRUDS/Pages";
import Users from "./modules/CRUDS/Users";
import BlockSettings from './modules/CRUDS/BlockSettings';
import Sites from './modules/CRUDS/Sites';
import Persons from './modules/CRUDS/Persons';
import SiteSwitcher from './modules/SiteSwitcher';
import Courses from './modules/CRUDS/Courses';
import Lessons from './modules/CRUDS/Lessons';
import Widgets from './modules/Widgets';
import Blocks from './modules/CRUDS/Blocks';
import BlockGroups from './modules/CRUDS/BlockGroups';
import Customers from './modules/CRUDS/Customers';
import Partners from './modules/CRUDS/Partners';
import Mails from './modules/CRUDS/Mails';
import Roles from './modules/CRUDS/Roles';
import Permissions from './modules/CRUDS/Permissions';
import Medias from './modules/CRUDS/Medias';
import Meta from "./modules/CRUDS/Meta";
import ActivityLogs from './modules/CRUDS/ActivityLogs';
import excel from './modules/excel';
import ExcelV2 from './modules/ExcelV2';
import DynamicFields from './modules/DynamicFields';
import Fields from './modules/CRUDS/Fields';
import FieldGroups from "./modules/CRUDS/FieldGroups";
import Catalog from "./modules/CRUDS/Catalog/Catalog";
import CatalogItem from "./modules/CRUDS/Catalog/CatalogItem";
import Constants from "./modules/Constants";
import ShortUrls from "./modules/CRUDS/ShortUrls";
import ShortUrlsStats from "./modules/CRUDS/ShortUrlsStats";
import RegionalPractices from "./modules/CRUDS/RegionalPractices";
import Patterns from "./modules/CRUDS/Patterns";
import KeyWords from "./modules/CRUDS/KeyWords";
import Cities from "./modules/CRUDS/Cities";
import Statistics from "./modules/Statistics";
import EntityOptions from "./modules/EntityOptions";
import DynamicTables from "./modules/CRUDS/DynamicTables";
import Languages from "./modules/CRUDS/Languages";
import Cron from "./modules/CRUDS/Cron";
import ChimeraCourses from './modules/CRUDS/ChimeraCourses';
import ChimeraLessons from './modules/CRUDS/ChimeraLessons';
import TempData from './modules/CRUDS/Temp';
import SystemPhrases from './modules/CRUDS/SystemPhrases';
import Events from './modules/CRUDS/Events';
import CatalogItemTable from './modules/CRUDS/Catalog/CatalogItemTable';
import WebmasterPartners from "./modules/CRUDS/WebmasterPartners";
import SeasonPass from "@/stores/modules/CRUDS/SeasonPass";
import Promocodes from "./modules/CRUDS/Promocodes";
import Teasers from "./modules/CRUDS/Teasers";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    block,
    options,
    Forms,
    snackbar,
    Regions,
    Pages,
    Users,
    BlockSettings,
    Sites,
    SiteSwitcher,
    Persons,
    Courses,
    Lessons,
    Widgets,
    Blocks,
    BlockGroups,
    Customers,
    Partners,
    Mails,
    Roles,
    Permissions,
    Medias,
    Meta,
    ActivityLogs,
    excel,
    ExcelV2,
    DynamicFields,
    Fields,
    FieldGroups,
    Catalog,
    CatalogItem,
    Constants,
    SeasonPass,
    ShortUrls,
    ShortUrlsStats,
    RegionalPractices,
    Patterns,
    KeyWords,
    Cities,
    Statistics,
    EntityOptions,
    DynamicTables,
    Languages,
    Cron,
    ChimeraCourses,
    ChimeraLessons,
    TempData,
    SystemPhrases,
    Events,
    CatalogItemTable,
    WebmasterPartners,
    Promocodes,
    Teasers,
  },
  state: {
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "#2f3136", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "rgb(124,58,237)", // primary
    setHorizontalLayout: false, // Horizontal layout
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
  },
  actions: {},
  getters: {},
})
