var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.fetchDetail)?_c('v-container',{staticClass:"down-top-padding",attrs:{"fluid":""}},[_c('BaseBreadcrumb',{attrs:{"title":_vm.page.title,"icon":_vm.page.icon,"breadcrumbs":_vm.breadcrumbs}}),_c('v-card',[_c('v-card-text',{staticClass:"pa-5"},[_c('v-tabs',{staticClass:"mb-5",attrs:{"background-color":"transparent","grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(" Основная информация ")]),_c('v-tab',[_vm._v(" Курсы ")]),_c('v-tab',[_vm._v(" Промокоды ")]),_c('v-tab',[_vm._v(" Абонемент ")]),_c('v-tab',[_vm._v(" Обратная связь ")]),_c('v-tab',[_vm._v(" Активность ")])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('UserForm',{attrs:{"user":_vm.fetchedUser},on:{"onSubmit":_vm.submit}})],1),_c('v-tab-item',[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.coursesDialog = true}}},[_vm._v(" Добавить ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.userCourses},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.preview_url)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":item.preview_url,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Предпросмотр курса")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.admin_attach_url)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.attachItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye-plus ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Предпросмотр купленного курса")])]),_c('v-btn',{attrs:{"href":_vm.editItem(item),"icon":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,2874245664)})],1),_c('v-tab-item',[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.promocodesDialog = true}}},[_vm._v(" Добавить ")]),_c('div',[_c('v-row',[_c('v-col',{staticClass:"col-md-12 mt-6"},[_c('h3',[_vm._v("Персональные промокоды")]),_c('v-data-table',{attrs:{"headers":_vm.promoHeaders,"items":_vm.userPromocodes},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.admin_attach_url)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.attachPromocode(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-eye-plus ")])],1):_vm._e()]}}],null,true)}),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deletePromocode(item)}}},[_vm._v(" mdi-delete ")])]}}],null,false,3784393560)})],1),_c('v-col',{staticClass:"col-md-12 mt-6"},[_c('h3',[_vm._v("Использованные промокоды")]),_c('v-data-table',{attrs:{"headers":_vm.promoHeaders,"items":_vm.usedPromocodes}})],1)],1)],1)],1),_c('v-tab-item',[_c('v-row',[_c('v-col',{staticClass:"col-md-12 mt-6"},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary"},on:{"click":function($event){_vm.seasonPassDialog = true}}},[_vm._v(" Добавить ")])],1),_c('v-col',{staticClass:"col-md-12 mt-6"},[_c('h3',[_vm._v("Абонементы пользователя")]),_c('v-data-table',{attrs:{"headers":_vm.seasonPassesHeaders,"items":_vm.userSeasonPasses},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"pill":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.is_active",fn:function(ref){
var item = ref.item;
return [(item.is_active)?[_c('v-chip',{attrs:{"color":"green","content":"да","label":""}},[_vm._v("да")])]:[_c('v-chip',{attrs:{"color":"red","content":"нет","label":""}},[_vm._v("нет")])]]}},{key:"item.recurrent_payment_active",fn:function(ref){
var item = ref.item;
return [(item.recurrent_payment_active)?[_c('v-chip',{attrs:{"color":"green","content":"да","label":""}},[_vm._v("да")])]:[_c('v-chip',{attrs:{"color":"red","content":"нет","label":""}},[_vm._v("нет")])]]}}],null,false,2302666340)})],1),_c('v-col',{staticClass:"col-md-12 mt-6"},[_c('h3',[_vm._v("Выбранные по абонементам продукты")]),_c('v-data-table',{attrs:{"headers":_vm.seasonPassesProductsHeaders,"items":_vm.userSeasonPassesProducts},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"pill":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.course_finished",fn:function(ref){
var item = ref.item;
return [(item.course_finished)?[_c('v-chip',{attrs:{"color":"green","content":"да","label":""}},[_vm._v("да")])]:[_c('v-chip',{attrs:{"color":"red","content":"нет","label":""}},[_vm._v("нет")])]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.course.preview_url)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":item.course.preview_url + '?preview_mode=true',"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary","small":""}},[_vm._v(" mdi-eye ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Предпросмотр курса")])])]}}],null,false,4211458764)})],1)],1)],1),_c('v-tab-item',[_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.leadsHeaders,"items":_vm.leads,"loading":_vm.leadsLoading,"options":_vm.options,"server-items-length":_vm.leadsTotal},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"pill":""}},[_vm._v(_vm._s(item.id))])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(_vm._s(item.human_type))])]}},{key:"item.status_title",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getStatusColor(item.status),"content":item.status_title,"label":""}},[_vm._v(_vm._s(item.status_title))])]}},{key:"item.changer",fn:function(ref){
var item = ref.item;
return [(item.changer && item.changer.full_name)?_c('span',[_vm._v(_vm._s(item.changer.full_name))]):_c('span',[_vm._v(" - ")])]}},{key:"item.comment",fn:function(ref){
var item = ref.item;
return [(item.comment && item.comment.text)?_c('span',[_vm._v(_vm._s(item.comment.text))]):_c('span',[_vm._v(" - ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"href":_vm.editLead(item),"icon":"","small":""}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,false,810891136)})],1)],1)],1),_c('v-tab-item',[_c('user-activity-form')],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.coursesDialog),callback:function ($$v) {_vm.coursesDialog=$$v},expression:"coursesDialog"}},[_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Добавление курса")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(_vm.courses)?_c('v-autocomplete',{attrs:{"items":_vm.courses,"item-text":"title","item-value":"id","label":"Выберите курс","name":"course","required":""},on:{"update:search-input":_vm.searchCourses},model:{value:(_vm.selectCourseId),callback:function ($$v) {_vm.selectCourseId=$$v},expression:"selectCourseId"}}):_vm._e()],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitAddCourse}},[_vm._v(" Ок ")])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.promocodesDialog),callback:function ($$v) {_vm.promocodesDialog=$$v},expression:"promocodesDialog"}},[_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Добавление промокода")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.promocodes,"item-value":"id","item-text":"title","label":"Выберите промокод","name":"promocode","no-filter":true},on:{"update:search-input":_vm.searchPromocode},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" - "+_vm._s(item.code)+" ")]}}],null,false,1699087127),model:{value:(_vm.selectPromocodeId),callback:function ($$v) {_vm.selectPromocodeId=$$v},expression:"selectPromocodeId"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitAddPromocode}},[_vm._v(" Ок ")])],1)],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.seasonPassDialog),callback:function ($$v) {_vm.seasonPassDialog=$$v},expression:"seasonPassDialog"}},[_c('v-card',[_c('v-container',[_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Добавление абонемента")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.seasonPasses,"item-value":"id","item-text":"title","label":"Выберите абонемент","name":"seasonPasses","no-filter":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title)+" - "+_vm._s(item.amount)+" ")]}}],null,false,1508621334),model:{value:(_vm.selectSeasonPassId),callback:function ($$v) {_vm.selectSeasonPassId=$$v},expression:"selectSeasonPassId"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.submitAddSeasonPass}},[_vm._v(" Ок ")])],1)],1)],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }