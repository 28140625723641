<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-row>
          <v-col>
            <h1>Редактирование события "{{ event.title || event.id }}"</h1>
          </v-col>
        </v-row>
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          class="mb-5"
        >
          <v-tab>
            Основная информация
          </v-tab>
          <v-tab>
            Таксономия
          </v-tab>
          <v-tab>
            SEO
          </v-tab>
          <v-tab>
            SMM
          </v-tab>
          <v-tab v-if="event.form && event.form.length > 0">
            Формы
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <event-form
              ref="form"
              :event="event"
              :media="media"
              :errors="errors"
              :is-edit="true"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <collection-relation-form
              module-name="Events"
              :errors="errors"
              :selected-collection-items="event.selected_collection_items"
              :main-items="event.main_taxonomy_ids"
              @onSubmit="submitCollections"
              @onSubmitMain="submitCollectionsMainItems"
              @onSubmitCourses="submitCourses"
              @onSubmitPersons="submitPersons"
            />
          </v-tab-item>
          <v-tab-item>
            <seo-form-separate
              :entity="event"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <smm-form-separate
              :entity="event"
              :errors="errors"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item v-if="event.form && event.form.length > 0">
            <component
              v-if="event.form[0].component"
              :is="event.form[0].component"
              :form="event.form[0].data"
              @mounted="handleFormMounted"
              ref="form$"
            />
          </v-tab-item>
        </v-tabs-items>
        <v-snackbar
          v-model="snackbarSuccess"
          :timeout="timeout"
          color="success"
        >
          Сохранено

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarSuccess = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
        <v-snackbar
          v-model="snackbarError"
          :timeout="timeout"
          color="error"
        >
          {{ this.errors }}

          <template v-slot:action="{ attrs }">
            <v-btn
              icon
              @click="snackbarError = false"
              color="red"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
        </v-snackbar>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import EventForm from '../../../components/events/EventForm';
import CollectionRelationForm from '../../../components/collections/CollectionRelationForm';
import {Event} from '../../../models/event';
import axiosInstance from "../../../axiosInstance";
import {Media} from "../../../models/media";
import SeoFormSeparate from "../../../components/SEOFormSeparate";
import SmmFormSeparate from "../../../components/SMMFormSeparate";
import { mapMutations } from "vuex";

export default {
  name: 'Edit',
  components: {
    EventForm,
    CollectionRelationForm,
    SeoFormSeparate,
    SmmFormSeparate,
  },
  data: () => ({
    event: {},
    media: {},
    errors: {},
    tab: 0,
    snackbarSuccess: false,
    snackbarError: false,
    timeout: 2000,
    page: {
      title: "Редактирование события"
    },
    breadcrumbs: [
      {
        text: "Админ панель",
        disabled: false,
        to: '/'
      },
      {
        text: "Календарь",
        disabled: true,
        to: '/events'
      },
      {
        text: "Редактирование",
        disabled: true,
        to: '/events/edit'
      },
    ],
  }),
  created() {
    this.find();
  },
  methods: {
    ...mapMutations({
      "setIsLoading": "Events/setIsLoading",
    }),
    async submit({ form, media, mediaIds, isNeedRedirect }) {
      try {
        this.errors = {};

        if (mediaIds) {
          form.mediaIds = mediaIds;
        }

        const event = await Event.$query().update(form.id, form);
        await event.partners().sync(form.selected_partners || []);
        await event.organizers().sync(form.selected_organizers || []);

        if (media && media.id) {
          await Media.$query().update(media.id, {...media, entity_id: event.$attributes.id});
        } else if (media) {
          await Media.$query().store({...media, entity_id: event.$attributes.id});
        }

        if (!mediaIds || mediaIds.length === 0) {
          await this.find();
        }

        this.snackbarSuccess = true;

        if (isNeedRedirect) {
          await this.$router.push({name: 'events-index'});
        }
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      } finally {
        this.setIsLoading(false);
      }
    },
    async submitCollections({ items, isNeedRedirect }) {
      try {
        this.errors = {};
        if (items.length > 0) {
          const event = await Event.$query().find(this.$route.params.id);
          await event.collectionItems().sync(items);
          if (isNeedRedirect) {
            this.$router.push({ name: 'events-index' });
          }
          await this.find();
        }
        this.snackbarSuccess = true;
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async submitCollectionsMainItems(items) {
      try {
        this.errors = {};
        await axiosInstance.post(`/module/events/${this.event.id}/collection-items/make-main`, {items});
        this.snackbarSuccess = true;
        await this.find();
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async submitCourses(items) {
      try {
        this.errors = {};
        await axiosInstance.post(`/module/events/${this.$route.params.id}/course-items/sync`, {items: items.items});
        this.snackbarSuccess = true;
        await this.find();
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async submitPersons(items) {
      console.log(items)
      try {
        this.errors = {};
        await axiosInstance.post(`/module/events/${this.$route.params.id}/persons/sync`, {items: items.items});
        this.snackbarSuccess = true;
        await this.find();
      } catch (e) {
        if (e.response?.data) {
          this.errors = e.response.data.errors;
          this.snackbarError = true;
        }
        throw e;
      }
    },
    async find() {
      const event = await Event.$query().find(this.$route.params.id);
      this.event = event.$attributes;

      const media = await Media.$query()
        .filter('entity', '=', 'Modules\\Events\\Entities\\Event')
        .filter('entity_id', '=', `${this.event.id}`).search(1);
      this.media = media && media.length > 0 ? media[0].$attributes : null;
    },
    handleFormMounted() {
      this.form$ = this.$refs.form$

      this.$emit('load', this.form$)
    }
  },
};
</script>
