<template>
  <v-container fluid class="down-top-padding" v-if="fetchDetail">
    <BaseBreadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></BaseBreadcrumb>
    <v-card>
      <v-card-text class="pa-5">
        <v-tabs
          v-model="tab"
          background-color="transparent"
          grow
          class="mb-5"
        >
          <v-tab>
            Основная информация
          </v-tab>
          <v-tab>
            Курсы
          </v-tab>
          <v-tab>
            Промокоды
          </v-tab>
          <v-tab>
            Абонемент
          </v-tab>
          <v-tab>
            Обратная связь
          </v-tab>
          <v-tab>
            Активность
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <UserForm
              :user="fetchedUser"
              @onSubmit="submit"
            />
          </v-tab-item>
          <v-tab-item>
            <v-btn
              class="ml-2"
              color="primary"
              @click="coursesDialog = true"
            >
              Добавить
            </v-btn>
            <v-data-table
              :headers="headers"
              :items="userCourses"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-if="item.preview_url"
                      :href="item.preview_url"
                      target="_blank"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="primary">
                        mdi-eye
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Предпросмотр курса</span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-if="item.admin_attach_url"
                      v-bind="attrs"
                      v-on="on"
                      @click="attachItem(item)"
                    >
                      <v-icon color="primary">
                        mdi-eye-plus
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Предпросмотр купленного курса</span>
                </v-tooltip>
                <v-btn
                  :href="editItem(item)"
                  icon
                >
                  <v-icon color="primary">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-icon
                  color="red"
                  @click="deleteItem(item)"
                >
                  mdi-delete
                </v-icon>
              </template>
            </v-data-table>
          </v-tab-item>
           <v-tab-item>
            <v-btn
              class="ml-2"
              color="primary"
              @click="promocodesDialog = true"
            >
              Добавить
            </v-btn>
            <div>
              <v-row>
                <v-col class="col-md-12 mt-6">
                  <h3>Персональные промокоды</h3>
                  <v-data-table
                    :headers="promoHeaders"
                    :items="userPromocodes"
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-if="item.admin_attach_url"
                            v-bind="attrs"
                            v-on="on"
                            @click="attachPromocode(item)"
                          >
                            <v-icon color="primary">
                              mdi-eye-plus
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-tooltip>
                      <v-icon
                        color="red"
                        @click="deletePromocode(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-col>
                <v-col class="col-md-12 mt-6">
                  <h3>Использованные промокоды</h3>
                  <v-data-table
                    :headers="promoHeaders"
                    :items="usedPromocodes"
                  />
                </v-col>
              </v-row>
            </div>

          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col class="col-md-12 mt-6">
                <v-btn
                  class="ml-2"
                  color="primary"
                  @click="seasonPassDialog = true"
                >
                  Добавить
                </v-btn>
              </v-col>
              <v-col class="col-md-12 mt-6">
                <h3>Абонементы пользователя</h3>
                <v-data-table
                  :headers="seasonPassesHeaders"
                  :items="userSeasonPasses"
                >
                  <template v-slot:item.id="{ item }">
                    <v-chip pill>{{ item.id }}</v-chip>
                  </template>
                  <template v-slot:item.is_active="{ item }">
                    <template v-if="item.is_active">
                      <v-chip color="green" content="да" label>да</v-chip>
                    </template>
                    <template v-else>
                      <v-chip color="red" content="нет" label>нет</v-chip>
                    </template>
                  </template>
                  <template v-slot:item.recurrent_payment_active="{ item }">
                    <template v-if="item.recurrent_payment_active">
                      <v-chip color="green" content="да" label>да</v-chip>
                    </template>
                    <template v-else>
                      <v-chip color="red" content="нет" label>нет</v-chip>
                    </template>
                  </template>
                </v-data-table>
              </v-col>
              <v-col class="col-md-12 mt-6">
                <h3>Выбранные по абонементам продукты</h3>
                <v-data-table
                  :headers="seasonPassesProductsHeaders"
                  :items="userSeasonPassesProducts"
                >
                  <template v-slot:item.id="{ item }">
                    <v-chip pill>{{ item.id }}</v-chip>
                  </template>
                  <template v-slot:item.course_finished="{ item }">
                    <template v-if="item.course_finished">
                      <v-chip color="green" content="да" label>да</v-chip>
                    </template>
                    <template v-else>
                      <v-chip color="red" content="нет" label>нет</v-chip>
                    </template>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-if="item.course.preview_url"
                          :href="item.course.preview_url + '?preview_mode=true'"
                          target="_blank"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="primary" small>
                            mdi-eye
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Предпросмотр курса</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="leadsHeaders"
                  :items="leads"
                  :loading="leadsLoading"
                  :options.sync="options"
                  :server-items-length="leadsTotal"
                >
                  <template v-slot:item.id="{ item }">
                    <v-chip pill>{{ item.id }}</v-chip>
                  </template>
                  <template v-slot:item.type="{ item }">
                    <v-chip>{{ item.human_type }}</v-chip>
                  </template>
                  <template v-slot:item.status_title="{ item }">
                    <v-chip :color="getStatusColor(item.status)" :content="item.status_title" label>{{ item.status_title }}</v-chip>
                  </template>
                  <template v-slot:item.changer="{ item }">
                    <span v-if="item.changer && item.changer.full_name">{{ item.changer.full_name }}</span>
                    <span v-else> - </span>
                  </template>
                  <template v-slot:item.comment="{ item }">
                    <span v-if="item.comment && item.comment.text">{{ item.comment.text }}</span>
                    <span v-else> - </span>
                  </template>

                  <template v-slot:item.actions="{item}">
                    <v-btn
                      :href="editLead(item)"
                      icon
                      small
                    >
                      <v-icon color="primary">
                        mdi-pencil
                      </v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item>
            <user-activity-form/>
          </v-tab-item>
        </v-tabs-items>

        <v-dialog
          v-model="coursesDialog"
          max-width="600px"
        >
          <v-card>
            <v-container>
              <v-row>
                <v-col>
                  <h3>Добавление курса</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-if="courses"
                    v-model="selectCourseId"
                    :items="courses"
                    item-text="title"
                    item-value="id"
                    label="Выберите курс"
                    name="course"
                    required
                    @update:search-input="searchCourses"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    @click="submitAddCourse"
                  >
                    Ок
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="promocodesDialog"
          max-width="600px"
        >
          <v-card>
            <v-container>
              <v-row>
                <v-col>
                  <h3>Добавление промокода</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="selectPromocodeId"
                    :items="promocodes"
                    item-value="id"
                    item-text="title"
                    label="Выберите промокод"
                    name="promocode"
                    :no-filter="true"
                    @update:search-input="searchPromocode"
                  >
                  <template v-slot:item="{ item }">
                    {{item.title}} - {{item.code}}
                  </template>
                </v-autocomplete>
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    @click="submitAddPromocode"
                  >
                    Ок
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <v-dialog
          v-model="seasonPassDialog"
          max-width="600px"
        >
          <v-card>
            <v-container>
              <v-row>
                <v-col>
                  <h3>Добавление абонемента</h3>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="selectSeasonPassId"
                    :items="seasonPasses"
                    item-value="id"
                    item-text="title"
                    label="Выберите абонемент"
                    name="seasonPasses"
                    :no-filter="true"
                  >
                    <template v-slot:item="{ item }">
                      {{item.title}} - {{item.amount}}
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    @click="submitAddSeasonPass"
                  >
                    Ок
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import UserForm from "../../../components/users/UserForm";
import {mapActions, mapGetters} from "vuex";
import axiosInstance from "../../../axiosInstance";
import {Course} from "../../../models/course";
import UserActivityForm from "../../../components/users/UserActivityForm";

export default {
  components: {
    UserForm,
    UserActivityForm,
  },
  name: "Edit",
  created() {
    this.fetchDetail(this.$route.params.id);
  },
  mounted() {
    this.getCourses();
    this.getUserCourses();
    this.getUserPromocodes();
    this.getPromocodes();
    this.getPromocodesOrders();
    this.getLeads();
    this.getSeasonPasses();
    this.getUserSeasonPasses();
    this.getUserSeasonPassesProducts();
  },
  data() {
    return {
      headers: [
        {text: 'Id', value: 'id'},
        {text: 'Название', value: 'title'},
        {text: 'Slug', value: 'slug'},
        {text: 'Дата создания', value: 'created_at'},
        {text: 'Дата обновления', value: 'updated_at'},
        {text: 'Дата поднятия', value: 'pickup_at'},
        {text: '', value: 'actions'},
      ],
      promoHeaders: [
        {text: 'Id', value: 'id'},
        {text: 'Промокод', value: 'code'},
        {text: 'Описание', value: 'title'},
        {text: 'Сценарий', value: 'type_title'},
        {text: 'Величина', value: 'amount'},
        {text: 'Начало действия', value: 'valid_from'},
        {text: 'Окончание действия', value: 'valid_to'},
        {text: 'Персональный промокод', value: 'is_user_promocode'},
        {text: '', value: 'actions'},
      ],
      leadsHeaders: [
        {text: 'Id', value: 'id'},
        {text: 'ФИО', value: 'name'},
        {text: 'Почта', value: 'email'},
        {text: 'Комментарий', value: 'comment'},
        {text: 'Тип', value: 'type'},
        {text: 'Статус изменен', value: 'changer'},
        {text: 'Статус', value: 'status_title', sortable: false},
        {text: 'Дата создания', value: 'created_at'},
        {text: 'Дата обновления', value: 'updated_at'},
        {text: '', value: 'actions'},
      ],
      seasonPassesHeaders: [
        {text: 'id', value: 'id'},
        {text: 'Название', value: 'title'},
        {text: 'Описание', value: 'description'},
        {text: 'Цена', value: 'amount'},
        {text: 'Количество дней', value: 'period'},
        {text: 'Количество видеоколлекций', value: 'available_quantity'},
        {text: 'Авто-продление', value: 'recurrent_payment_active'},
        {text: 'Активен', value: 'is_active'},
        {text: 'Дата окончания', value: 'expiration_date'},
        {text: 'Дата создания', value: 'created_at'},
        {text: 'Дата обновления', value: 'updated_at'},
        {text: '', value: 'actions'},
      ],
      seasonPassesProductsHeaders: [
        {text: 'id', value: 'id'},
        {text: 'id пользователя', value: 'user_id'},
        {text: 'id абонемента', value: 'season_pass_id'},
        {text: 'id курса', value: 'course_id'},
        {text: 'Назваие курса', value: 'course.title'},
        {text: 'Курс завершён', value: 'course_finished'},
        {text: 'Дата создания', value: 'created_at'},
        {text: 'Дата обновления', value: 'updated_at'},
        {text: '', value: 'actions'},
      ],
      userCourses: [],
      courses: [],
      coursesDialog: false,
      selectCourseId: null,
      tab: 0,
      page: {
        title: "Редактирование пользователя"
      },
      breadcrumbs: [
        {
          text: "Админ панель",
          disabled: false,
          to: '/'
        },
        {
          text: "Пользователи",
          disabled: true,
          to: '/users'
        },
        {
          text: "Редактирование",
          disabled: true,
          to: '/users/edit'
        },
      ],
      userPromocodes: [],
      promocodes: [],
      promocodesOrders: [],
      usedPromocodes: [],
      seasonPasses: [],
      userSeasonPasses: [],
      userSeasonPassesProducts: [],
      promocodesDialog: false,
      selectPromocodeId: null,
      seasonPassDialog: false,
      selectSeasonPassId: null,
      options: {
        sortBy: ['created_at'],
        sortDesc: [true],
      },
      leads: [],
      leadsTotal: null,
      leadsLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      fetchedUser: 'Users/getFetchedDetail',
    })
  },
  methods: {
    ...mapActions({
      fetchDetail: 'Users/fetchDetail',
      update: 'Users/update'
    }),
    async submit(form) {
      console.log(form);
      await this.update(form).then(() => {
        this.$router.push({name: 'users'});
      });
    },
    async getUserCourses() {
      this.userCourses = (await axiosInstance.get(`/auth/user/${this.$route.params.id}/courses`)).data.data
    },
    async getCourses(search = '') {
      let courses = [];
      if(search){
        courses = await Course.$query().lookFor(search).search(15);
      }else{
        courses = await Course.$query().get(50);
      }

      this.courses = courses.map((c) => c.$attributes);
    },
    editItem(item) {
      return this.$router.resolve({name: 'courses-edit', params: {id: item.id}}).href
    },
    deleteItem(item) {
      axiosInstance.get(`/auth/user/${this.fetchedUser.id}/course/${item.id}/detach`)
        .then(() => {
          this.getUserCourses();
        })
    },
    attachItem(item) {
      axiosInstance.get(`/auth/user/course/${item.id}/attach`)
        .then((response) => {
          window.open(response.data);
        });
    },
    submitAddCourse() {
      if (this.selectCourseId) {
        axiosInstance.get(`/auth/user/${this.fetchedUser.id}/course/${this.selectCourseId}/attach`)
          .then(() => {
            this.selectCourseId = null;
            this.getUserCourses();
            this.coursesDialog = false;
          })
      }
    },
    async submitAddSeasonPass() {
      if (this.selectSeasonPassId) {
        axiosInstance.get(`/auth/user/${this.fetchedUser.id}/season-pass/${this.selectSeasonPassId}/attach`)
          .then(() => {
            this.selectSeasonPassId = null;
            this.getUserSeasonPasses();
            this.seasonPassDialog = false;
          })
      }
    },
    async getUserPromocodes() {
      this.userPromocodes = (await axiosInstance.get(`/auth/user/${this.$route.params.id}/promocodes`)).data.data
    },
    async getPromocodes(search = '') {

      const data = {};
      search ? data.search = {value: search} : null;
      data.sort = [{"field" : "id", "direction" : "desc"}];

      if(this.userPromocodes.length)
        data.filters = [
            {
              "field" : "id",
              "operator" : "not in",
              "value" : this.userPromocodes.map(element => element.id),
            },
        ];

      await axiosInstance.post(`/promocodes/search?limit=15`, data)
        .then((response) => {
          this.promocodes = response.data.data;
        });
    },
    searchPromocode(value) {
      if(value){
        if(value.length > 3)
          this.getPromocodes(value);
      }else{
        this.getPromocodes();
      }
    },
    async getPromocodesOrders() {
      let filter =
        {
          "filters": [
            {
              "field" : "promocode_id",
              "operator" : "!=",
              "value" : null,
            },
             {
              "field" : "user_id",
              "operator" : "=",
              "value" : this.$route.params.id,
            },
          ],
        };
        (await axiosInstance.post(`/orders/search?limit=2000`,  filter,
          ).then((res) => {
            this.promocodesOrders = res.data.data;
            if(this.promocodesOrders)
              this.getPromocodesById(this.promocodesOrders.map(element => element.promocode_id));
          })
          .catch((err) => {
            console.log(err);
          }));
    },
    async getPromocodesById(id) {
      if(id.length){
        let filter =
        {
          "filters": [
            {
              "field" : "id",
              "operator" : "in",
              "value" : id
            },
          ],
        };
        (await axiosInstance.post(`/promocodes/search?limit=2000`,  filter,
          ).then((res) => {
            this.usedPromocodes = res.data.data;
          })
          .catch((err) => {
            console.log(err);
          }));
          let promocodesId = this.userPromocodes.map(element => element.id);
          this.usedPromocodes.forEach(function(element) {
            element.is_user_promocode = promocodesId.includes(element.id) ? 'Да' : 'Нет';
          });
      }
    },
    async getLeads() {
     this.leadsLoading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.options;
      let data = {filters: []};
      sortBy && sortBy[0] ? data.sort = [{field: sortBy[0], direction: sortDesc[0] ? 'desc' : 'asc'}] : null;

      data.filters.push({field: 'comment', operator: '!=', value: null});
      data.filters.push({field: 'email', operator: '=', value: this.fetchedUser.email});

      await axiosInstance.post(`/leads/search?limit=${itemsPerPage}&page=${page}`, data).then((response) => {
        this.leads = response.data.data;
        this.leadsTotal = response.data.meta.total;
      })
      this.leadsLoading = false;
    },
    editLead(item) {
      return this.$router.resolve({name: 'leads-edit', params: {id: item.id}}).href
    },
    getStatusColor(status) {
      switch (status) {
        case 0:
          return 'red';
        case 1:
          return 'yellow';
        case 2:
          return 'green';
        default:
          return 'grey';
      }
    },
    submitAddPromocode() {
      if (this.selectPromocodeId) {
        axiosInstance.get(`/auth/user/${this.fetchedUser.id}/promocodes/${this.selectPromocodeId}/attach`)
          .then(() => {
            this.selectPromocodeId = null;
            this.getUserPromocodes();
            this.promocodesDialog = false;
          })
      }
    },
    deletePromocode(item) {
      axiosInstance.get(`/auth/user/${this.fetchedUser.id}/promocodes/${item.id}/detach`)
        .then(() => {
          this.getUserPromocodes();
        })
    },
    attachPromocode(item) {
      axiosInstance.get(`/auth/user/${this.fetchedUser.id}/promocodes/${item.id}/attach`)
        .then((response) => {
          window.open(response.data);
        });
    },
    searchCourses(value) {
      if(value){
        if(value.length > 3)
          this.getCourses(value);
      }
    },
    async getSeasonPasses() {
      const { data: { data: response } } = await axiosInstance.post(`/season-pass/client-list/`);

      this.seasonPasses = response;
    },
    async getUserSeasonPasses() {
      const { data: { data: response } } = await axiosInstance.post(`/season-pass/admin/get-sp-by-user-id/${this.$route.params.id}`);

      this.userSeasonPasses = response;
    },
    async getUserSeasonPassesProducts() {
      const { data: { data: response } } = await axiosInstance.post(`/season-pass/admin/get-sp-products-by-user-id/${this.$route.params.id}`);

      this.userSeasonPassesProducts = response;
    },
  },
  watch: {
    'fetchedUser.full_name': {
      handler(newTitle) {
        this.page.title +=  ` "${newTitle}"`;
      }
    },
    options: {
      handler() {
        this.getLeads();
      },
      deep: true,
    },
    userPromocodes: {
      handler() {
        this.getPromocodes();
      },
    },
  }
}
</script>
