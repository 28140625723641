import Article from './article';
import Answers from './answers';
import Page from './page';
import Block from './block';
import Role from './role';
import Course from './course';
import Collections from './collections';
import Profile from './profile';
import Regions from './regions';
import Source from './source';
import History from './history';
import Person from './person';
import Subscriber from './subscriber';
import Library from './library';
import User from './user';
import Event from './event';
import PartnerResource from './partnerResource';
import Main from '../../views/main/Index.vue';
import Permission from './permissions';
import PortalOptions from './portalOptions';
import Suggest from './suggest';
import Landing from './landing';
import FunctionalIcons from './functionalIcons';
import Organizer from './organizer';
import Partner from './partner';
import Lead from './lead';
import Media from './media';
import Promocode from './promocode';
import Lesson from './lesson';
import Order from './order';
import Review from './review';
import ReviewType from './reviewType';
import Form from './form';
import Teaser from './teaser';
import Site from "./site";
import Customer from './customer';
import Mails from './mails';
import Logging from "./logging";
import Fields from "./fields";
import FieldGroups from "./fieldGroups";
import Statistic from "./statistic";
import Catalogs from "./catalogs";
import shortUrls from './shortUrls';
import seasonPass from './seasonPass';
import RegionalPractices from "./regionalPractices";
import StatisticsV2 from './statisticsV2'
import Languages from './language';
import Cron from './cron';
import ChimeraCourses from './chimeraCourses';
import ChimeraLessons from './chimeraLessons';
import Temp from './temp';
import SystemPhrases from './systemPhrases';
import Briefs from './briefs';
import webmasterPartners from './webmasterPartners';
import PartnersStatistic from './partners-statistic';
import FAQs from './faqs';
import Promocodes from './promocode';

export default [
  ...Article,
  ...Page,
  ...Block,
  ...Role,
  ...Collections,
  ...Course,
  ...Profile,
  ...Regions,
  ...Source,
  ...History,
  ...Person,
  ...Subscriber,
  ...Library,
  ...PartnerResource,
  ...User,
  ...Permission,
  ...Event,
  ...PortalOptions,
  ...Suggest,
  ...Landing,
  ...FunctionalIcons,
  ...Organizer,
  ...Partner,
  ...Lead,
  ...Media,
  ...Promocode,
  ...Lesson,
  ...Order,
  ...Review,
  ...ReviewType,
  ...Form,
  ...Teaser,
  ...Site,
  ...Customer,
  ...Mails,
  ...Logging,
  ...Fields,
  ...FieldGroups,
  ...Statistic,
  ...Catalogs,
  ...seasonPass,
  ...shortUrls,
  ...RegionalPractices,
  ...StatisticsV2,
  ...Languages,
  ...Cron,
  ...ChimeraCourses,
  ...ChimeraLessons,
  ...Temp,
  ...SystemPhrases,
  ...Answers,
  ...Briefs,
  ...webmasterPartners,
  ...PartnersStatistic,
  ...FAQs,
  ...Promocodes,
  {
    component: Main,
    name: 'main-index',
    path: '/',
    meta: {
      layout: 'main-layout',
      requiresAuth: true,
    }
  }
]
