<template>
  <v-form >
    <v-row>
      <v-col>
        <v-card>
          <v-text-field

            v-model="form.title"
            :error-messages="errors.title"
            label="Название"
            name="title"
            required
          ></v-text-field>
          <v-text-field

            v-model="form.slug"
            :error-messages="errors.slug"
            label="Slug"
            name="slug"

          ></v-text-field>
          <a
            v-if="event.preview_url"
            :href="event.preview_url"
            target="_blank"
            class="mr-3"
          >
            {{ event.preview_url }}
          </a>
          <preview v-if="event.preview_url" :previewUrl="event.preview_url"/>
          <!--
          <v-text-field

            v-model="form.price"
            type="number"
            label="Стоимость"
          />
          <v-text-field

            v-model="form.location"
            :error-messages="errors.location"
            label="Адрес проведения"
            name="location"
            required
          ></v-text-field>
          <v-autocomplete

            v-model="form.region_id"
            :error-messages="errors.region_id"
            :items="regions"
            item-text="title"
            item-value="id"
            label="Выберите регион"
            name="region_id"
            chips
            required
          ></v-autocomplete>
          <v-autocomplete

            v-model="form.partner_id"
            :error-messages="errors.partner_id"
            :items="partners"
            item-text="title"
            item-value="id"
            label="Выберите ответственного оператора"
            name="partner_id"
            chips
            required
          ></v-autocomplete>
          <v-autocomplete
            v-model="form.selected_organizers"

            :items="partners"
            item-text="title"
            item-value="id"
            label="Выберите организаторов"
            multiple
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="removeOrganizers(data.item)"
              >
                <v-avatar left>
                  <v-img :src="data.item.image"/>
                </v-avatar>
                {{ data.item.title }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <v-list-item-avatar>
                <img :src="data.item.image">
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-autocomplete
            v-model="form.selected_partners"

            :items="partners"
            item-text="title"
            item-value="id"
            label="Выберите партнеров"
            multiple
          >
            <template v-slot:selection="data">
              <v-chip
                v-bind="data.attrs"
                :input-value="data.selected"
                close
                @click="data.select"
                @click:close="removePartners(data.item)"
              >
                <v-avatar left>
                  <v-img :src="data.item.image"/>
                </v-avatar>
                {{ data.item.title }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <v-list-item-avatar>
                <img :src="data.item.image">
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ data.item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          -->
          <v-select

            v-model="form.status"
            :error-messages="errors.status"
            :items="statuses"
            label="Статус"
            name="status"
            required
          ></v-select>
          <v-select v-model="form.type" label="Тип" :items="types"/>
          <!--
                    <v-text-field v-if="form.type !== 2" v-model="form.meeting_link" label="Ссылка на встречу"/>
                    <v-text-field
                      v-model="form.notification_email"
                      :error-messages="errors.notification_email"
                      label="Email для уведомления"
                      name="notification_email"
                      required
                    ></v-text-field>
                     -->
          <v-checkbox v-model="form.for_sale" label="Мероприятие продает билеты" />
          <v-text-field
            v-model="form.amount"
            :error-messages="errors.amount"
            label="Цена билета"
            name="amount"
            type="number"
            required
          ></v-text-field>
          <v-text-field
            v-model="form.facecast_code"
            :error-messages="errors.facecast_code"
            label="Код трансляции из Facecast"
            name="facecast_code"
            required
          ></v-text-field>
          <!--
          <v-datetime-picker
            v-model="form.start_video_date"
            scrollable
            :time-picker-props="timeProps"
            label="Дата и время начала видео"
            clear-text="Очистить"
          ></v-datetime-picker>
          <v-datetime-picker
            v-model="form.end_video_date"
            scrollable
            :time-picker-props="timeProps"
            label="Дата и время завершения видео"
            clear-text="Очистить"
          ></v-datetime-picker>

          <v-card v-if="!event.parent && events " class="mb-5">
            <v-card-title>
              Привязка мероприятий для серии мероприятий
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-autocomplete
                    item-text="title"
                    item-value="id"
                    placeholder="Выберите мероприятие"
                    multiple
                    :items="events"
                    v-model="form.selected_events"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="removeChild(data.item)"
                      >
                        <v-avatar left>
                          <v-img :src="data.item.icon"/>
                        </v-avatar>
                        {{ data.item.title }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <v-list-item-avatar>
                        <img :src="data.item.icon">
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.title }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <v-card v-else-if="event.parent " class="mb-5">
            <v-card-title>
              Родительское мероприятие
            </v-card-title>
            <v-card-subtitle>
              К данному элементу невозможно привязать дочерние мероприятия, т.к он уже используется
            </v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title v-text="event.parent.title"/>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn fab dark color="primary" small :href="editParent(event.parent.id)">
                        <v-icon color="white">mdi-pen</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          -->
          <media-widget

            class="mb-1"
            label="Анонсное изображение"
            :medias="form.preview_image"
            :is-multiple="false"
            @onChange="setPreviewImage"
          />
          <v-textarea

            name="lead"
            label="Лид"
            v-model="form.lead"
            :error-messages="errors.lead"
            required
          ></v-textarea>
          <label>Контент</label>
          <media-form
            :key="reRenderMedia"
            ref="media-form"
            :structure="currentMedia.structure"
            :is-show-save="false"
            style="margin: 0 auto"
          />
          <!--
          <v-checkbox v-model="form.is_hidden" label="Скрыть со списочных" />-->
          <v-flex class="mb-2">
            <v-btn
              v-if="isEdit && isModuleBlockEnabled"
              :href="`${clientUrl}/events/${form.slug}?edit_mode=true&admin_url=${this.$route.fullPath}`"
              color="primary"
              class="mr-2 mt-2"
              @click="toBlocks"
            >
              Редактирование блоков
            </v-btn>
          </v-flex>
          <v-flex>
            <v-btn
              class="mr-4"
              color="primary"
              :disabled="isSaveDisabled"
              :loading="isLoading"
              @click="submit(true)"
            >
              Сохранить
            </v-btn>
            <v-btn
              class="mr-4"
              color="primary"
              :disabled="isSaveDisabled"
              :loading="isLoading"
              @click="submit(false)"
            >
              Сохранить и продолжить
            </v-btn>
          </v-flex>

          <media-uploader
            :loaded-medias="event.loaded_medias"
            @completeUploads="onCompleteUploads"
          />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-datetime-picker
          v-model="form.published_at"
          :time-picker-props="timeProps"
          scrollable

          label="Дата публикации. По умолчанию текущая дата и время"
          clear-text="Очистить"
        ></v-datetime-picker>
        <!--
        <v-row>
          <v-col>
            <v-checkbox

              v-model="form.date_is_not_confirmed"
              :true-value="1"
              :false-value="0"
              label="Точная дата уточняется"
              class="mt-auto mb-auto"
            />
          </v-col>
          <v-col>
            <v-checkbox

              v-model="form.is_pinned"
              label="Закрепить на главной"
              class="mt-auto mb-auto"
            />
          </v-col>
        </v-row>
        -->
        <v-card >
          <v-card-title>
            Даты
          </v-card-title>
          <v-card-text class="px-0">
            <v-container>
              <v-row>
                <v-col>

                  <v-datetime-picker
                    v-model="form.event_date"
                    :time-picker-props="timeProps"
                    scrollable

                    label="Дата начала"
                    clear-text="Очистить"
                  ></v-datetime-picker>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <v-row>
                <v-col>
                  <v-datetime-picker

                    v-model="form.event_date_end"
                    scrollable
                    :time-picker-props="timeProps"
                    label="Дата окончания"
                    clear-text="Очистить"
                  ></v-datetime-picker>
                </v-col>
              </v-row>
            </v-container>
            <v-container v-for="(event_event_date, index) in form.event_dates" :key="index"
                         :class="{'grey lighten-5': index % 2}">
              <v-row>
                <v-col>
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        <label for="input-test" class="v-label v-label--active v-label--is-disabled theme--light" style="left: 0px; right: auto; position: absolute; font-size: 14px;">Дата начала</label>
                        <vxe-input type="datetime" class="mt-4" v-model="event_event_date.start_date" :disabled="true"/>
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col>
                  <div class="v-input__control">
                    <div class="v-input__slot">
                      <div class="v-text-field__slot">
                        <label for="input-test-2" class="v-label v-label--active v-label--is-disabled theme--light" style="left: 0px; right: auto; position: absolute; font-size: 14px;">Дата конца</label>
                        <vxe-input type="datetime" name="input-test-2" class="mt-4" v-model="event_event_date.start_date" :disabled="true"/>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col class="py-0">
                  <v-autocomplete :disabled="true" :items="partners" v-model="event_event_date.partner_id"
                                  item-value="id"
                                  item-text="title" label="Выберите партнера"/>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
        <v-btn block color="primary" @click="dateDialog = true" class="mb-5"  >Редактировать даты</v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="dateDialog" persistent max-width="900">
      <v-card>
        <v-card-title>
          Добавление даты
          <v-btn icon @click="addDate" :loading="isLoading">
            <v-icon color="green">mdi-plus</v-icon>
          </v-btn>
          <v-card-actions>
            <v-btn block color="primary" class="ml-3 mr-3" @click="saveDate" :disabled="!dates_is_valid" :loading="isLoading">
              Сохранить
            </v-btn>
          </v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon @click="dateDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container ref="test" class="mb-5">
            <template v-for="(event_date, index) in event_dates">
              <ValidationObserver :ref="`observer_${index}`" :key="index" v-slot="{ invalid }">
                <v-row>
                  <v-col cols="3">
                    <validation-provider :name="`start_date_${index}`" rules="required">
                      <div class="v-input__control">
                        <div class="v-input__slot">
                          <div class="v-text-field__slot">
                            <label for="input-test" class="ml-1 v-label v-label--active v-label--is-disabled theme--light" style="left: 0px; right: auto; position: absolute; font-size: 14px;">Дата начала</label>
                            <vxe-input
                              class="mt-5"
                              :name="`start_date_${index}`"
                              v-model="event_date.start_date"
                              type="datetime"
                              clearable
                            />
                          </div>
                        </div>
                      </div>
                    </validation-provider>
                  </v-col>
                  <v-col cols="3">
                    <validation-provider :name="`end_date_${index}`" rules="required">
                      <div class="v-input__control">
                        <div class="v-input__slot">
                          <div class="v-text-field__slot">
                            <label for="input-test" class="ml-1 v-label v-label--active v-label--is-disabled theme--light" style="left: 0px; right: auto; position: absolute; font-size: 14px;">Дата конца</label>
                            <vxe-input
                              class="mt-5"
                              :name="`end_date_${index}`"
                              v-model="event_date.end_date"
                              type="datetime"
                              clearable
                            />
                          </div>
                        </div>
                      </div>

                    </validation-provider>
                  </v-col>
                  <v-col cols="4">
                    <validation-provider :name="`partner_id_${index}`" rules="required">
                      <v-autocomplete
                        :name="`partner_id_${index}`"
                        :items="partners"
                        @change="selectPartner"
                        v-model="event_date.partner_id"
                        item-value="id"
                        item-text="title"
                        label="Выберите партнера"
                      />
                    </validation-provider>
                  </v-col>
                  <v-col>
                    <v-btn icon @click="deleteDate(index)" class="mt-3">
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                    <v-icon :color="invalid ? 'red' : 'green'" class="mt-3">
                      {{ invalid ? 'mdi-close' : 'mdi-check' }}
                    </v-icon>
                  </v-col>
                </v-row>
              </ValidationObserver>
            </template>
            <br> <br> <br> <br> <br>&nbsp;
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import MediaForm from '../medias/MediaForm';
import slugify from 'slugify';
import moment from 'moment';
import MediaUploader from '../MediaUploader';
import MediaWidget from '../MediaWidget';
import axiosInstance from "../../axiosInstance";
import {  mapGetters, mapMutations } from 'vuex';

export default {
  name: "EventForm",
  props: {
    event: {
      type: Object,
      default: () => ({})
    },
    media: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MediaForm,
    MediaUploader,
    MediaWidget,
  },
  data: () => ({
    form: {
      id: null,
      region_id: null,
      partner_id: null,
      title: null,
      slug: null,
      published_at: null,
      event_date: null,
      event_date_end: null,
      location: null,
      preview_image: null,
      status: null,
      type: 2,
      meeting_link: null,
      selected_partners: null,
      selected_organizers: null,
      selected_events: [],
      lead: null,
      notification_email: null,
      facecast_code: null,
      start_video_date: null,
      end_video_date: null,
      date_is_not_confirmed: 0,
      is_hidden: false,
      is_pinned: false,
      event_dates: [],
      price: null,
      for_sale: null,
      amount: null,
    },
    event_dates: [],
    dates_is_valid: true,
    dateDialog: false,
    event_date: {
      start_date: null,
      end_date: null,
      partner_id: null,
    },
    timeProps: {
      format: '24hr',
      useSeconds: false,
      timeFormat: 'HH',
    },
    currentMedia: {
      id: null,
      entity: 'Modules\\Events\\Entities\\Event',
      entity_id: null,
      structure: null,
    },
    statuses: [
      {text: 'Черновик', value: 0},
      {text: 'Опубликовано', value: 1},
    ],
    types: [
      {text: 'Онлайн', value: 0},
      {text: 'Смешанный', value: 1},
      {text: 'Место', value: 2},
    ],
    reRenderMedia: 1,
    mediaIds: [],
    regions: [],
    partners: [],
    events: null,
    isSaveDisabled: false,
    clientUrl: process.env.VUE_APP_CLIENT_URL,
    isLoaded: false,
    entity: 'Modules\\\\Events\\\\Entities\\\\Event',
  }),
  created() {
    if (this.$route.params.media) {
      this.currentMedia = JSON.parse(this.$route.params.media);
    }

    if (this.$route.params.data) {
      this.form = JSON.parse(this.$route.params.data);
    }

    this.getRegions();
    this.getPartners();
    this.getEvents();

  },
  computed: {
    ...mapGetters({
      isLoading: 'Events/isLoading',
    }),
    isModuleBlockEnabled() {
      return process.env.VUE_APP_M_BLOCKS === 'true';
    }
  },
  methods: {
    ...mapMutations({
      setIsLoading: 'Events/setIsLoading',
    }),
    async onCompleteUploads(ids) {
      this.mediaIds = ids;
      await this.submit(true);
      this.mediaIds = [];
    },
    async submit(isNeedRedirect) {
      this.setIsLoading(true);

      const data = await this.$refs['media-form'].submit();

      if (data) {
        this.currentMedia.structure = data;
      }

      this.form.published_at = this.form.published_at
        ? moment(this.form.published_at).format('YYYY-MM-DD HH:mm')
        : null;
      this.form.event_date = this.form.event_date
        ? moment(this.form.event_date).format('YYYY-MM-DD HH:mm')
        : null;
      this.form.event_date_end = this.form.event_date_end
        ? moment(this.form.event_date_end).format('YYYY-MM-DD HH:mm')
        : null;
      this.form.start_video_date = this.form.start_video_date
        ? moment(this.form.start_video_date).format('YYYY-MM-DD HH:mm')
        : null;
      this.form.end_video_date = this.form.end_video_date
        ? moment(this.form.end_video_date).format('YYYY-MM-DD HH:mm')
        : null;

      this.$emit('onSubmit', {
        form: this.form,
        media: this.currentMedia,
        mediaIds: this.mediaIds,
        isNeedRedirect: isNeedRedirect,
      });
    },
    setPreviewImage(image) {
      this.form.preview_image = image;
    },
    async getRegions() {
      await axiosInstance.get('module/admin/regions/select-list').then((response) => {
        this.regions = response.data.data;
      });
    },
    async getPartners() {
      await axiosInstance.get('partners/select-list').then((response) => {
        this.partners = response.data.data;
      });
    },
    async getOrganizers() {
      await axiosInstance.get('organizers/select-list').then((response) => {
        this.partners = response.data.data;
      });
    },
    toBlocks() {
      this.$store.dispatch('block/updatePageForm', this.form);
    },
    async getEvents() {
      await axiosInstance.get(`module/events/select-list/${this.$route.params.id}`).then((response) => {
        this.events = response.data.data;
      });
    },
    deleteSelectedElement(index) {
      this.form.selected_events.splice(index, 1);
    },
    editParent(id) {
      return this.$router.resolve({name: 'events-edit', params: {id: id}}).href;
    },
    removeOrganizers(item) {
      const index = this.form.selected_organizers.indexOf(item.id);
      if (index >= 0) this.form.selected_organizers.splice(index, 1);
    },
    removePartners(item) {
      const index = this.form.selected_partners.indexOf(item.id);
      if (index >= 0) this.form.selected_partners.splice(index, 1);
    },
    removeChild(item) {
      const index = this.form.selected_events.indexOf(item.id);
      if (index >= 0) this.form.selected_events.splice(index, 1);
    },
    addDate() {
      this.event_dates.unshift(JSON.parse(JSON.stringify(this.event_date)));
      // this.event_dates.push(JSON.parse(JSON.stringify(this.event_date)));
    },
    deleteDate(index) {
      this.event_dates.splice(index, 1);
    },
    saveDate() {
      this.form.event_dates = JSON.parse(JSON.stringify(this.event_dates));
      this.submit(false);
    },
    selectPartner(value){
      this.partners.sort(function(x,y){
        return x.id == value ? -1 : y.id == value ? 1 : 0;
      });
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event && Object.keys(event).length > 0) {
          this.form = event;
          this.isSaveDisabled = false;
          this.event_dates = JSON.parse(JSON.stringify(event.event_dates));
        }
      },
      immediate: true,
      deep: true,
    },
    media: {
      handler(media) {
        if (media && Object.keys(media).length > 0) {
          this.currentMedia = JSON.parse(JSON.stringify(media));
          this.reRenderMedia += 1;
        }
      },
      immediate: true,
      deep: true,
    },
    'form.title': {
      handler(value) {
        if (value && Object.keys(this.event).length === 0) {
          this.form.slug = slugify(value.toLowerCase()).match(/[a-z]|[0-9]|[-]/gm).join('');
        }
      }
    },
    event_dates: {
      handler() {
        this.$nextTick(async () => {
          if (Array.isArray(this.event_dates)) {
            this.event_dates.forEach(async (item, index) => {

              if(item.start_date && !item.end_date)
                item.end_date = item.start_date;

              this.dates_is_valid = true;
              const observer = this.$refs[`observer_${index}`];

              if (observer[0]) {
                const result = await observer[0].validate()

                this.dates_is_valid = this.dates_is_valid && result;
              }
            });
          }
        })
      },
      deep: true,
    }
  },
}
</script>
<style>
.type--datetime .vxe-input--panel-layout-wrapper .vxe-input--panel-left-wrapper {
  order: 2;
}
.type--datetime .vxe-input--panel-layout-wrapper .vxe-input--panel-right-wrapper {
  order: 1;
}
</style>
