<template>
  <div>
    <v-form class="row">
      <v-text-field
        class="col"
        v-model="form.title"
        label="Название"
        name="title"
        required
      ></v-text-field>
  <!--    <v-text-field-->
  <!--      class="col"-->
  <!--      v-model="form.slug"-->
  <!--      label="slug"-->
  <!--      name="slug"-->
  <!--      required-->
  <!--    ></v-text-field>-->
      <v-text-field
        class="col"
        v-model="form.description"
        label="Описание"
        name="description"
        required
      ></v-text-field>
      <v-select
        label="Выберите тип абонемента"
        v-model="form.type"
        :items="seasonPassTypes"
        item-value="value"
        item-text="title"
      />
      <v-text-field
        class="col"
        v-model="form.amount"
        label="Стоимость"
        name="amount"
        required
      ></v-text-field>
      <div class="v-input col v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
        <v-checkbox v-model="form.recurrent_payments" label="Рекурентный" />
      </div>
      <template v-if="form.recurrent_payments === true || form.recurrent_payments === 1">
        <v-text-field
          class="col"
          v-model="form.first_purchase_cost"
          label="Стоимость первой покупки"
          name="first_purchase_cost"
          required
        ></v-text-field>
      </template>
      <v-text-field
        class="col"
        v-model="form.period"
        label="Количество дней"
        name="period"
        required
      ></v-text-field>
      <v-text-field
        class="col"
        v-model="form.limit"
        label="Лимит курсов по абонементу (с учётом исключений). Оставьте поле пустым, чтобы отключить лимит"
        name="limit"
      ></v-text-field>
      <v-text-field
        class="col"
        v-model="form.available_quantity"
        label="Доступное количество курсов"
        name="available_quantity"
        required
      ></v-text-field>
      <div class="v-input col v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted">
        <v-checkbox v-model="form.active" label="Активность" />
      </div>
      <v-btn
        color="primary"
        class="mr-2"
        :disabled="isSaveButtonDisabled"
        :loading="isSaveButtonDisabled"
        @click="submit(true)"
      >Сохранить</v-btn>
      <v-btn
        color="primary"
        class="mr-2"
        :disabled="isSaveButtonDisabled"
        :loading="isSaveButtonDisabled"
        @click="submit(false)"
      >Сохранить и продолжить</v-btn>
    </v-form>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'SeasonPassForm',
  props: {
    entity: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    form: {},
    isSaveDisabled: false,
    seasonPassTypes: [
      { title: 'По умолчанию', value: 0 },
      { title: 'Акционный', value: 1 },
    ],
  }),
  computed: {
    ...mapGetters({
      isSaveButtonDisabled: "SeasonPass/isSaveButtonDisabled",
    }),
  },
  watch: {
    entity: {
      handler(entity) {
        if (entity && Object.keys(entity).length > 0) {
          this.form = entity;
          this.isSaveDisabled = false;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async submit(isNeedRedirect) {
      this.isSaveDisabled = true;

      this.$emit('onSubmit', {form: this.form, isNeedRedirect: isNeedRedirect, router: this.$router});
    },
  }
}
</script>
